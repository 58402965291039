import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { IPage } from '../../types'
import { BreadcrumbsFl } from '../BreadcrumbsFl/BreadcrumbsFl'
import { EditMe } from '../EditMe'
import { PageGridItemToComponent } from '../itemToComponent'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import {
  Container,
  TaboolaWrapper,
  Title,
  TitleContainer,
} from './AuthorCategoryTagFl.styles'
import { ShareButtons } from '../ShareButtons/ShareButtons'

type Props = {
  page: IPage
  isHomepage?: boolean
}

export const AuthorCategoryTagFl = ({
  page: {
    PageMeta,
    Header,
    Content: { PageGrid },
    SEO,
  },
  isHomepage,
}: Props) => {
  const pageType = PageMeta.pageType
  const disableTaboola = PageMeta.ads.block.taboola
  const { asPath } = useRouter()
  const taboolaType = useMemo(() => {
    const isNewsHp = ['/news/', '/news'].includes(asPath)
    return isHomepage
      ? PocTaboolaPageType.home
      : isNewsHp
      ? PocTaboolaPageType.news_hp
      : PocTaboolaPageType.category
  }, [isHomepage, asPath])

  const TaboolaElement = useMemo(() => {
    // add taboola for HP and Grid above the fourth last block

    return (
      <TaboolaWrapper key={'TaboolaWrapper'}>
        <Taboola
          pageType={taboolaType}
          key={'t1' + taboolaType}
          suffixId={PocTaboolaSuffixId.widget}
          index={1}
        />
      </TaboolaWrapper>
    )
  }, [taboolaType])

  const items = useMemo(() => {
    let skipBlocksCount = 0
    const blocks =
      PageGrid?.map((item, index) => {
        if (item.grid_type === 'Separator') {
          skipBlocksCount++
        }
        return (
          <EditMe
            key={`wp-${index}-${item.grid_id || 0}`}
            grid_id={item.grid_id}
            children={PageGridItemToComponent(item, PageMeta, index + 1)}
          />
        )
      }) || []

    if (
      disableTaboola ||
      (!isHomepage && pageType !== 'grid') ||
      blocks.length <= 2
    ) {
      return blocks
    }

    if (blocks.length > 7) {
      blocks.splice(blocks.length - 4, 0, TaboolaElement)
    } else if (blocks.length > 5) {
      blocks.splice(blocks.length - 3, 0, TaboolaElement)
    } else {
      blocks.splice(2, 0, TaboolaElement)
    }

    // add taboola for HP and Grid after the 8th element not counting the separator
    taboolaType &&
      isHomepage &&
      blocks.length > 9 + skipBlocksCount &&
      blocks.splice(
        8 + skipBlocksCount,
        0,
        <TaboolaWrapper key={'TaboolaWrapper_2'}>
          <Taboola
            pageType={taboolaType}
            key={'t2' + taboolaType}
            suffixId={PocTaboolaSuffixId.widget}
            index={2}
          />
        </TaboolaWrapper>
      )

    return blocks
  }, [
    PageGrid,
    disableTaboola,
    isHomepage,
    pageType,
    taboolaType,
    PageMeta,
    TaboolaElement,
  ])

  if (!PageMeta || !PageGrid || !Header) {
    return null
  }

  return (
    <Container theme={{ haseWarComp: PageMeta.war_comp }}>
      {!isHomepage && PageMeta.pageType === 'category' && (
        <>
          <BreadcrumbsFl breadcrumbs={Header.breadCrumbs} />
          <TitleContainer>
            <Title theme={{ color: PageMeta.themed.font_color }}>
              {PageMeta.title}
            </Title>
            <ShareButtons
              size={'16'}
              isFaceLift={PageMeta.isFaceLift}
              SEO={SEO}
            />
          </TitleContainer>
        </>
      )}
      {items}
      {taboolaType && !disableTaboola && (
        <Taboola
          key={taboolaType}
          pageType={taboolaType}
          suffixId={PocTaboolaSuffixId.pageFooter}
        />
      )}
    </Container>
  )
}
