import dynamic from 'next/dynamic'
import {
  IBaseFl,
  IGridVodItem,
  ILiveVodPageGrid,
  IPageMeta,
  IPaginationFl,
  isAdvertisers,
  isAllShows,
  isArticleGrid_fl,
  isBanner,
  isBase,
  isCategoryOpener,
  isCategoryOpenerExtended,
  isContestantsComponent,
  isContestantsFLComponent,
  isEpisodesGridFl,
  isEpisodesRowFLComponent,
  isHorizontalNewsFlashes,
  isIFrameBlock,
  IsImageCounterComponent,
  isLayout3,
  isLayout4,
  isLayout6,
  isLayout7,
  isLiveBlock,
  isLiveBroadcast,
  isMainStandardItem,
  isMainTextOverItem,
  isMatrix1x4,
  isMatrix2x4,
  isMidBoxMobile,
  isOpeningBannerFl,
  isOpinion,
  isPager,
  isPodcastFLComponent,
  isPublishersComponent,
  isReelsFl,
  isSeasonFLComponent,
  isSeparator,
  isSpecialRef,
  isSpecialRefFl,
  isTermHeadBlock,
  isTimeline,
  isTimelineFl,
  isVerticalBlock,
  IsVideoGridComponent,
  isVod,
  isVodEpisodes,
  isVodGenresBlock,
  isVodLeft,
  isVodMain,
  isVodPlaylist,
  isVodPrograms,
  ITermHeadBlockFl,
  IVodItem,
  PageGridItem,
} from '../types'
import { Banner } from './Ads/Banner'
import { Advertisers } from './Advertisers/Advertisers'
import { Base } from './Base/Base'
import { CategoryOpener } from './CategoryOpener/CategoryOpener'
import { CategoryOpenerExtended } from './CategoryOpener/CategoryOpenerExtended'
import { Contestants } from './Contestants/Contestants'
import { HorizontalNewsFlashes, LiveBlock } from './GridBlocks'
import { GridVideo } from './GridVideo/GridVideo'
import { Hero } from './Hero/Hero'
import { ImageCounter } from './ImageCounter/ImageCounter'
import {
  ContestantsFl,
  EpisodesGridFl,
  EpisodesRowFL,
  PodcastFL,
  ReelsFl,
  SeasonFL,
  SpecialRefFl,
  TermHeadBlockFl,
} from './IndexFlComponents'
import { Layout3, Layout4, Layout6, Layout7 } from './Layout'
import { IFrame, LiveBroadcast, MainStandard, MainTextOver } from './Main'
import { Matrix1x4, Matrix2x4 } from './Matrix'
import { Opinions } from './Opinions/Opinions'
import { Publishers } from './Publishers/Publishers'
import { SpecialRef } from './SpecialRef/SpecialRef'
import { TermHeadBlock } from './TermHeadBlock/TermHeadBlock'
import { Vod } from './Vod/Vod'
import { VodEpisodes } from './VodEpisodes/VodEpisodes'
import { VodGenresCarousel } from './VodGenresCarousel/VodGenresCarousel'
import { VodLeft } from './VodLeft/VodLeft'
import { VodPlaylist } from './VodPlaylist/VodPlaylist'
import { VodPrograms } from './VodPrograms/VodPrograms'

export const commonItemToComponent = ({
  item,
  i,
}: {
  item: IGridVodItem | IVodItem
  i: number
}) => {
  const { grid_id } = item

  if (isMainStandardItem(item)) {
    return <MainStandard mainStandard={item} key={`${i} ${grid_id}`} />
  }
  if (isLayout6(item)) {
    return <Layout6 layout={item} key={`${i} ${grid_id}`} />
  }
  if (isLayout7(item)) {
    return <Layout7 index={i} layout={item} key={`${i} ${grid_id}`} />
  }
  if (isBanner(item)) {
    return <Banner banner={item} key={`${i} ${grid_id}`} collapse={false} />
  }
  if (isLayout3(item)) {
    return <Layout3 index={i} layout={item} key={`${i} ${grid_id}`} />
  }
  if (isMatrix2x4(item)) {
    return <Matrix2x4 matrix={item} key={`${i} ${grid_id}`} />
  }
  if (isMatrix1x4(item)) {
    return <Matrix1x4 matrix={item} key={`${i} ${grid_id}`} />
  }
  if (isVod(item)) {
    return <Vod vod={item} key={`${i} ${grid_id}`} />
  }
  if (isSpecialRef(item)) {
    return <SpecialRef specialRef={item} key={`${i} ${grid_id}`} />
  }
  if (isLayout4(item)) {
    return <Layout4 layout={item} key={`${i} ${grid_id}`} />
  }
  if (isMainTextOverItem(item)) {
    return <MainTextOver mainTextOver={item} key={`${i} ${grid_id}`} />
  }
  if (isLiveBroadcast(item)) {
    return (
      <LiveBroadcast
        liveBroadcast={item}
        key={`${i} ${grid_id}`}
        isFirst={false}
      />
    )
  }
  if (isIFrameBlock(item)) {
    return <IFrame iFrame={item} key={`${i} ${grid_id}`} />
  }

  return null
}

export const gridVodItemToComponent = ({
  item,
  i,
}: {
  item: IGridVodItem
  i: number
}) => {
  const { grid_id } = item

  if (isVodLeft(item)) {
    return <VodLeft vodLeft={item} key={`${i} ${grid_id}`} />
  }
  if (isVodPlaylist(item)) {
    return <VodPlaylist vodPlaylist={item} key={grid_id} />
  }

  return null
}

export const vodItemToComponent = ({
  item,
  i,
}: {
  item: IVodItem
  i: number
}) => {
  const { grid_id } = item

  if (isVodMain(item)) {
    return <Hero item={item} key={`${i} ${grid_id}`} />
  }
  if (isVodEpisodes(item)) {
    return <VodEpisodes item={item} key={`${i} ${grid_id}`} />
  }
  if (isVodPrograms(item)) {
    return <VodPrograms item={item} key={`${i} ${grid_id}`} />
  }
  if (isVodGenresBlock(item)) {
    return <VodGenresCarousel item={item} key={`${i} ${grid_id}`} />
  }
  if (isAllShows(item)) {
    const AllShows = dynamic(() => import('./AllShows/AllShows'))
    return <AllShows item={item} key={`${i} ${grid_id}`} />
  }

  return null
}

export const PageGridItemToComponent = (
  item: PageGridItem,
  pageMeta: IPageMeta,
  position?: number
) => {
  if (isTermHeadBlock(item)) {
    if (pageMeta.isFaceLift) {
      return (
        <TermHeadBlockFl
          termHead={item as unknown as ITermHeadBlockFl}
          key={item.term_id}
        />
      )
    }
    return <TermHeadBlock termHead={item} key={item.term_id} />
  }

  const { grid_id } = item

  if (IsVideoGridComponent(item)) {
    return <GridVideo video={item} key={item.term_id} />
  }

  if (IsImageCounterComponent(item)) {
    return <ImageCounter image_counter={item} />
  }

  if (isMainStandardItem(item)) {
    return <MainStandard mainStandard={item} key={grid_id} />
  }
  if (isMainTextOverItem(item)) {
    return <MainTextOver mainTextOver={item} key={grid_id} />
  }
  if (isLiveBroadcast(item)) {
    return (
      <LiveBroadcast
        liveBroadcast={item}
        key={grid_id}
        isFirst={position === 0}
      />
    )
  }
  if (isLayout3(item)) {
    return <Layout3 index={position || 1} layout={item} key={grid_id} />
  }
  if (isLayout4(item)) {
    return <Layout4 layout={item} key={grid_id} />
  }
  //Episodes for FaceLift project
  if (isEpisodesGridFl(item)) {
    return <EpisodesGridFl layout={item} key={grid_id} />
  }
  //Reels for FaceLift project
  if (isReelsFl(item)) {
    return <ReelsFl layout={item} key={grid_id} />
  }
  if (isLayout6(item)) {
    return <Layout6 layout={item} key={grid_id} />
  }
  if (isLayout7(item)) {
    return <Layout7 index={position || 1} layout={item} key={grid_id} />
  }
  if (isMatrix1x4(item)) {
    return <Matrix1x4 matrix={item} key={grid_id} />
  }
  if (isMatrix2x4(item)) {
    return <Matrix2x4 matrix={item} key={grid_id} />
  }
  if (isPublishersComponent(item)) {
    return <Publishers publishers={item} key={grid_id} />
  }
  if (isContestantsComponent(item)) {
    return <Contestants contestants={item} key={grid_id} />
  }
  if (isContestantsFLComponent(item)) {
    return <ContestantsFl contestants={item} key={grid_id} />
  }
  if (isSeasonFLComponent(item)) {
    return <SeasonFL season={item} key={grid_id} />
  }
  if (isEpisodesRowFLComponent(item)) {
    return <EpisodesRowFL layout={item} key={grid_id} />
  }
  if (isPodcastFLComponent(item)) {
    return <PodcastFL podcast={item} key={grid_id} />
  }
  if (isCategoryOpener(item)) {
    return <CategoryOpener categoryOpener={item} key={grid_id} />
  }
  if (isCategoryOpenerExtended(item)) {
    return <CategoryOpenerExtended categoryOpenerExt={item} key={grid_id} />
  }
  if (isIFrameBlock(item)) {
    return <IFrame iFrame={item} key={grid_id} />
  }
  if (isVod(item)) {
    return <Vod vod={item} key={grid_id} />
  }
  if (isSeparator(item)) {
    const Separator = dynamic(() => import('./Separator/Separator'))
    return <Separator separator={item} key={grid_id} />
  }
  if (isHorizontalNewsFlashes(item)) {
    return <HorizontalNewsFlashes newsFlashes={item} key={grid_id} />
  }
  if (isSpecialRef(item)) {
    return <SpecialRef specialRef={item} key={grid_id} />
  }
  if (isBase(item)) {
    if (pageMeta.isFaceLift) {
      const BaseFl = dynamic(() => import('./BaseFl/BaseFl'), { ssr: false })
      return (
        <BaseFl
          base={item as unknown as IBaseFl}
          pageType={pageMeta.pageType}
          blockTaboola={pageMeta.ads.block.taboola}
          key={grid_id}
        />
      )
    }
    return (
      <Base
        base={item}
        pageType={pageMeta.pageType}
        blockTaboola={pageMeta.ads.block.taboola}
        key={grid_id}
      />
    )
  }
  if (isPager(item)) {
    if (pageMeta.isFaceLift) {
      const PagerFl = dynamic(() => import('./PagerFl/PagerFl'))
      return item.max_page > 1 ? (
        <PagerFl pager={item as unknown as IPaginationFl} key={grid_id} />
      ) : null
    }
    const Pager = dynamic(() => import('./Pager/Pager'))
    return item.max_page > 1 ? <Pager pager={item} key={grid_id} /> : null
  }
  if (isSpecialRefFl(item)) {
    return <SpecialRefFl specialRef={item} key={grid_id} />
  }
  if (isOpeningBannerFl(item)) {
    const OpeningBannerFl = dynamic(
      () => import('./OpeningBanner/OpeningBannerFl')
    )

    return <OpeningBannerFl openingBanner={item} key={grid_id} />
  }
  if (isBanner(item)) {
    return <Banner banner={item} collapse={false} key={grid_id} />
  }
  if (isOpinion(item)) {
    return <Opinions opinionsAndPosts={item} key={grid_id} />
  }
  if (isTimeline(item)) {
    const TimeLineBlock = dynamic(() => import('./GridBlocks/TimeLineBlock'))
    return <TimeLineBlock {...item} key={grid_id} />
  }
  if (isTimelineFl(item)) {
    const TimeLineBlockFl = dynamic(
      () => import('./GridBlocks/TimeLineBlockFl')
    )
    return <TimeLineBlockFl {...item} key={grid_id} />
  }
  if (isLiveBlock(item as ILiveVodPageGrid)) {
    return <LiveBlock {...(item as ILiveVodPageGrid)} key={grid_id} />
  }

  if (isArticleGrid_fl(item)) {
    const ArticlesGrid_fl = dynamic(
      () => import('./ArticlesGrid/ArticlesGrid_fl')
    )
    return <ArticlesGrid_fl layout={item} key={grid_id} />
  }

  if (isAdvertisers(item)) {
    return <Advertisers layout={item} key={grid_id} />
  }
  if (isMidBoxMobile(item)) {
    const MidBoxMobile = dynamic(
      () => import('./GridBlocks/MidBoxMobile/MidBoxMobile'),
      { ssr: false }
    )
    return <MidBoxMobile key={grid_id} position={position || 1} />
  }
  if (isVerticalBlock(item)) {
    // const VerticalPlayerBlock = dynamic(
    //   () => import('./GridBlocks/VerticalPlayerBlock/VerticalPlayerBlock')
    // )
    // return <VerticalPlayerBlock key={grid_id} data={item} />
    const Reels = dynamic(() => import('./Reels/Reels'))
    return <Reels key={grid_id} data={item} />
  }

  return <></>
}
