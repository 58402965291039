import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { GridBlockProvider } from '../../contexts'
import { IPage, IsImageCounterComponent, isSeparator } from '../../types'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { EditMe } from '../EditMe'
import { PageGridItemToComponent } from '../itemToComponent'
import { PageTitle } from '../PageTitle/PageTitle'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import { Container, TaboolaWrapper } from './AuthorCategoryTag.styles'

type Props = {
  page: IPage
  isHomepage?: boolean
}

export const AuthorCategoryTag = ({
  page: {
    SEO,
    PageMeta,
    Header,
    Content: { PageGrid },
  },
  isHomepage,
}: Props) => {
  const pageType = PageMeta.pageType
  const disableTaboola = PageMeta.ads.block.taboola
  const { asPath } = useRouter()

  const taboolaType = useMemo(() => {
    const slugs = asPath.split('/').filter(slug => !!slug)
    const isNewsHp = slugs[0] === 'news'
    if (isHomepage) {
      return PocTaboolaPageType.home
    }
    if (isNewsHp) {
      return PocTaboolaPageType.news_hp
    }
    return PocTaboolaPageType.category
  }, [isHomepage, asPath])

  const TaboolaElement = useMemo(() => {
    // add taboola for HP and Grid above the fourth last block

    return (
      <TaboolaWrapper key={'TaboolaWrapper'}>
        <Taboola
          pageType={taboolaType}
          key={'t1' + taboolaType}
          suffixId={PocTaboolaSuffixId.widget}
          index={1}
        />
      </TaboolaWrapper>
    )
  }, [taboolaType])

  const items = useMemo(() => {
    let skipBlocksCount = 0
    const blocks =
      PageGrid?.map((item, index) => {
        if (item.grid_type === 'Separator') {
          skipBlocksCount++
        }
        const name = item?.newsroom_name || 'newsroom_' + item.grid_id
        const addNewsRoomWrapper =
          isHomepage &&
          !isSeparator(item) &&
          !IsImageCounterComponent(item) &&
          name !== 'mid_box'
        return (
          <EditMe
            key={`wp-${index}-${item.grid_id || 0}`}
            grid_id={item.grid_id}
          >
            <GridBlockProvider gridId={item.grid_id}>
              {addNewsRoomWrapper ? (
                <div data-tb-region={name}>
                  {PageGridItemToComponent(item, PageMeta, index + 1)}
                </div>
              ) : (
                PageGridItemToComponent(item, PageMeta, index + 1)
              )}
            </GridBlockProvider>
          </EditMe>
        )
      }) || []

    if (
      disableTaboola ||
      (!isHomepage && pageType !== 'grid') ||
      blocks.length <= 2
    ) {
      return blocks
    }

    if (blocks.length > 7) {
      blocks.splice(blocks.length - 4, 0, TaboolaElement)
    } else if (blocks.length > 5) {
      blocks.splice(blocks.length - 3, 0, TaboolaElement)
    } else {
      blocks.splice(2, 0, TaboolaElement)
    }

    if (
      isHomepage &&
      taboolaType &&
      blocks.length > 9 + skipBlocksCount &&
      blocks[8 + skipBlocksCount].key !== 'TaboolaWrapper'
    ) {
      // add taboola for HP and Grid after the 8th element not counting the separator
      blocks.splice(
        8 + skipBlocksCount,
        0,
        <TaboolaWrapper key={'TaboolaWrapper_2'}>
          <Taboola
            pageType={taboolaType}
            key={'t2' + taboolaType}
            suffixId={PocTaboolaSuffixId.widget}
            index={2}
          />
        </TaboolaWrapper>
      )
    }

    return blocks
  }, [
    PageGrid,
    disableTaboola,
    isHomepage,
    pageType,
    taboolaType,
    PageMeta,
    TaboolaElement,
  ])

  if (!PageMeta || !PageGrid || !Header) {
    return null
  }
  return (
    <Container
      theme={{
        haseWarComp: PageMeta.war_comp,
        isFaceLift: PageMeta.isFaceLift,
      }}
    >
      {!isHomepage && (
        <>
          <PageTitle SEO={SEO} pageMeta={PageMeta} />
          <Breadcrumbs breadcrumbs={Header.breadCrumbs} />
        </>
      )}
      {items}
      {taboolaType && !disableTaboola && (
        <Taboola
          key={taboolaType}
          pageType={taboolaType}
          suffixId={PocTaboolaSuffixId.pageFooter}
        />
      )}
    </Container>
  )
}
