import { AdId, useGridBlock } from '../../../contexts'
import { ILayout7 } from '../../../types'
import Ad from '../../Ads/Ad'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { Post } from '../../Post/Post'
import { Banner, Container, PostsContainer } from './Layout7.styles'
import { useGridStore } from '../../../store/gridStore'
import { useEffect, useState } from 'react'

type Props = {
  layout: ILayout7
  index: number
}

export const Layout7 = ({ layout, index }: Props) => {
  const posts = layout.posts.filter(post => post.image != '')
  const posts1 = posts.slice(0, 3)
  const posts2 = posts.slice(3)

  const { grid_title, layout_mobile, isIframe } = layout
  const { gridId } = useGridBlock()

  const { pushAdUnit, adUnitArray } = useGridStore()
  const [adUnit, setAddUnit] = useState<string | null>(null)
  const [collapse, setCollapse] = useState(false)

  useEffect(() => {
    if (!isIframe) {
      pushAdUnit({ id: index, adUnit: layout.adUnit })
    }
  }, [index, isIframe, layout.adUnit, pushAdUnit])

  useEffect(() => {
    if (adUnitArray.length > 0) {
      let found = 1
      for (let i = 0; i < adUnitArray.length; i++) {
        if (adUnitArray[i].adUnit === layout.adUnit) {
          if (adUnitArray[i].id === index) {
            break
          }
          found++
        }
      }
      setAddUnit(`${layout.adUnit}_${found}`)
    }
  }, [adUnitArray, index, layout.adUnit])

  const onBannerRendered = (e: googletag.events.SlotRenderEndedEvent) => {
    if (e.isEmpty) {
      setCollapse(true)
    }
  }

  return posts ? (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <PostsContainer>
        {posts1.map(post => (
          <Post
            key={post.id}
            post={post}
            layoutMobile={layout_mobile}
            height='263px'
            hideSecondaryTitle
          />
        ))}
        {!isIframe && (
          <Banner
            key={gridId}
            className={adUnit || ''}
            theme={{ collapse: collapse }}
          >
            {adUnit && <Ad id={adUnit as AdId} onRender={onBannerRendered} />}
          </Banner>
        )}
        {posts2.map(post => (
          <Post
            key={post.id}
            post={post}
            layoutMobile={layout_mobile}
            hideSecondaryTitle
          />
        ))}
      </PostsContainer>
    </Container>
  ) : null
}
