import styled from 'styled-components'
import { accessibilityZIndex, device } from '../../../styles/GlobalVariables'

interface IVerticalPlayerViewWrapperProps {
  showPlayer?: boolean | undefined
  onTouchStart?: (e: React.TouchEvent<HTMLDivElement>) => void
}

export const VerticalPlayerViewWrapper = styled.div<IVerticalPlayerViewWrapperProps>`
  display: ${props => (props.showPlayer ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: ${accessibilityZIndex + 1};
  justify-content: center;
  align-items: center;
  padding: 0;
  direction: ltr;

  ${device.small} {
    direction: rtl;
    display: ${props => (props.showPlayer ? 'flex' : 'none')};
    padding: 3px;
  }

  //pointer-events: none;
  //touch-action: none;
  //overflow: hidden;
`
export const VerticalPlayerViewClose = styled.div`
  position: absolute;
  top: 83px;
  right: 4vw;
  width: 32px;
  height: 32px;
  z-index: 22;
  cursor: pointer;
`
export const VerticalPlayerPlayerWrapper = styled.div`
  position: relative;
  width: 100dvw;
  height: 100dvh;
  display: block;

  ${device.small} {
    display: flex;
    max-width: 768px;
    max-height: 80vh;
    min-height: 80vh;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;

    ${device.small} {
      height: 100%;
    }
  }
`
