import styled from 'styled-components'
import { fonts } from '../../../styles/GlobalVariables'

interface VerticalPlayerItemDivProps {
  poster?: string
}
export const VerticalPlayerItemDiv = styled.div<VerticalPlayerItemDivProps>`
  position: relative;
  aspect-ratio: 9/16;
  background-color: rgb(98, 97, 97);
  display: flex;
  flex-direction: column;
  //justify-content: center;
  background-image: url(${props => props.poster});
  background-size: cover;
  background-position: center center;

  align-items: stretch;
  justify-content: flex-end;
  padding: 5px;
  cursor: pointer;
`
export const VerticalPlayerItemTitle = styled.h3`
  font-family: ${fonts.narkis};
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
export const VerticalPlayerItemDuration = styled.div`
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
`
