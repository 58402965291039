import { usePage } from '../../../contexts'
import { useFL } from '../../../hooks/useFL'
import { ITimelineBlockFl } from '../../../types'
import ComponentTitleFL from '../../ComponentTitle/ComponentTitleFL'
import { Container, Desktop, DesktopContainer, Mobile } from './StyleFl.styles'
import TimeLineDesktop from './TimeLineDesktop'
import TimeLineMobile from './TimeLineMobile'

const TimeLineBlockFl = ({ timeline, grid_id }: ITimelineBlockFl) => {
  const page = usePage()
  const isFaceLift = page?.PageMeta.isFaceLift
  const { fontColor, isDarkTheme } = useFL()
  const pageId = page?.PageMeta.id

  return (
    <Container theme={{ isFaceLift: isFaceLift }}>
      <Desktop>
        <ComponentTitleFL
          componentTitle={timeline.grid_title}
          componentId={grid_id}
        />
        <DesktopContainer>
          {timeline.elements.slice(0, 4).map(el => {
            return (
              <TimeLineDesktop
                timeline={el}
                pId={pageId}
                gid={grid_id}
                fontColor={fontColor}
              />
            )
          })}
        </DesktopContainer>
      </Desktop>
      <Mobile>
        <TimeLineMobile
          timeline={timeline.elements[0]}
          pId={pageId}
          gid={grid_id}
          fontColor={fontColor}
          isDarkTheme={isDarkTheme}
        />
      </Mobile>
    </Container>
  )
}
export default TimeLineBlockFl
