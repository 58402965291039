import {
  VerticalPlayerPlayerWrapper,
  VerticalPlayerViewClose,
  VerticalPlayerViewWrapper,
} from './VerticalPlayerView.style'
import { IVerticalBlockItem } from '../../../types'
import { CloseIcon } from '../../../icons'
import { Player } from './Player'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { VerticalPlayerSwiperButton } from './VerticalPlayerBlock.style'
import { Chevron } from './icons'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { PlayerContext } from '../../Player/Store/PlayerContext'

SwiperCore.use([Navigation])

interface VerticalPlayerViewProps {
  showPlayer?: boolean
  closePlayer: () => void
  clickedVideo?: number | null
  posts?: IVerticalBlockItem[]
}

export const VerticalPlayerView = ({
  showPlayer,
  closePlayer,
  clickedVideo,
  posts,
}: VerticalPlayerViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const swiperRef = useRef<SwiperCore | null>(null)
  const [swiperProps, setSwiperProps] = useState<null | SwiperProps>(null)
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const playerCtx = React.useContext(PlayerContext)
  const { setVideoPlaying } = playerCtx

  useEffect(() => {
    setVideoPlaying(!!showPlayer)
  }, [setVideoPlaying, showPlayer])

  const [nowPlaying, setNowPlaying] = useState(clickedVideo || 0)
  useEffect(() => {
    setNowPlaying(clickedVideo || 0)
  }, [clickedVideo])

  const handleSlideChangeTransitionStart = useCallback(() => {
    pauseAllPlayers()
  }, [])

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(nowPlaying)
      handleSlideChangeTransitionStart()
    }
  }, [handleSlideChangeTransitionStart, nowPlaying])

  useEffect(() => {
    if (prevRef.current && nextRef.current) {
      setSwiperProps({
        modules: [Navigation],
        slidesPerView: 1,
        slidesPerGroup: 1,
        initialSlide: nowPlaying,
        touchAngle: 90,
        loop: true,
        cssMode: false,
        navigation: {
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        },
        breakpoints: {
          // Desktop/tablet (> 768px)
          768: {
            direction: 'horizontal',
          },
          // Mobile (≤ 768px)
          0: {
            mousewheel: true,
            direction: 'vertical',
            height: window.innerHeight, // Add a fixed height for vertical scrolling
          },
        },
      })
    }
  }, [nowPlaying])
  const preventTouchLeaks = (e: React.TouchEvent<HTMLDivElement>) => {
    // e.preventDefault()
    e.stopPropagation()
  }
  const pauseAllPlayers = () => {
    Object.keys(window.KalturaPlayer.getPlayers()).map(playerId =>
      window.KalturaPlayer.getPlayer(playerId).pause()
    )
  }
  const handleClosePlayer = () => {
    closePlayer()
    pauseAllPlayers()
  }
  if (!posts) {
    return null
  }
  return (
    <VerticalPlayerViewWrapper
      showPlayer={showPlayer}
      ref={containerRef}
      onTouchStart={preventTouchLeaks}
      onTouchEnd={preventTouchLeaks}
      onScroll={preventTouchLeaks}
    >
      <VerticalPlayerViewClose onClick={handleClosePlayer}>
        <CloseIcon />
      </VerticalPlayerViewClose>
      <VerticalPlayerPlayerWrapper>
        {showPlayer && (
          <Swiper
            {...swiperProps}
            onSlideChangeTransitionStart={handleSlideChangeTransitionStart}
            onSwiper={swiper => (swiperRef.current = swiper)}
          >
            {posts.map((post, index) => (
              <SwiperSlide key={index}>
                <Player video={post.video} img={post.poster} index={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <VerticalPlayerSwiperButton role={'next'} ref={nextRef}>
          <Chevron />
        </VerticalPlayerSwiperButton>
        <VerticalPlayerSwiperButton role={'prev'} ref={prevRef}>
          <Chevron />
        </VerticalPlayerSwiperButton>
      </VerticalPlayerPlayerWrapper>
    </VerticalPlayerViewWrapper>
  )
}
