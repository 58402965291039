import {
  VerticalPlayerBlockWrapper,
  VerticalPlayerSwiperButton,
  VerticalPlayerSwiperWrap,
} from './VerticalPlayerBlock.style'
import { IVerticalBlock } from '../../../types'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { VerticalPlayerItem } from './VerticalPlayerItem'
import { useEffect, useRef, useState } from 'react'
import { VerticalPlayerView } from './VerticalPlayerView'
import { Arrow } from './icons'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

const VerticalPlayerBlock = ({ data }: { data: IVerticalBlock }) => {
  const { grid_title, posts } = data
  const swiperRef = useRef<SwiperCore | null>(null)
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const [swiperProps, setSwiperProps] = useState<null | SwiperProps>(null)
  const [showPlayer, setShowPlayer] = useState<boolean>(false)
  const clickedVideo = useRef<number | null>(null)

  useEffect(() => {
    if (prevRef.current && nextRef.current) {
      setSwiperProps({
        loop: true,
        modules: [Navigation],
        spaceBetween: 16,
        watchSlidesProgress: true,
        navigation: {
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        },
        breakpoints: {
          // Desktop/tablet (> 768px)
          768: {
            slidesPerView: 6,
            slidesPerGroup: 5,
          },
          // Mobile (≤ 768px)
          0: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
          },
        },
      })
    }
  }, [])
  const handleSlideClick = (i: number) => {
    setShowPlayer(true)
    clickedVideo.current = i + 1
  }
  const handleClosePlayer = () => {
    setShowPlayer(false)
  }

  return (
    <>
      <VerticalPlayerBlockWrapper>
        {grid_title && <ComponentTitle componentTitle={grid_title} />}
        <VerticalPlayerSwiperWrap>
          {swiperProps && (
            <Swiper
              {...swiperProps}
              onSwiper={swiper => (swiperRef.current = swiper)}
            >
              {posts.map((data, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => handleSlideClick(index)}
                >
                  <VerticalPlayerItem data={data} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <VerticalPlayerSwiperButton role={'next'} ref={nextRef}>
            <Arrow />
          </VerticalPlayerSwiperButton>
          <VerticalPlayerSwiperButton role={'prev'} ref={prevRef}>
            <Arrow />
          </VerticalPlayerSwiperButton>
        </VerticalPlayerSwiperWrap>
      </VerticalPlayerBlockWrapper>
      <VerticalPlayerView
        showPlayer={showPlayer}
        closePlayer={handleClosePlayer}
        clickedVideo={clickedVideo.current}
        posts={posts}
      />
    </>
  )
}
export default VerticalPlayerBlock
