import styled from 'styled-components'
import { device } from '../../../styles/GlobalVariables'

export const PlayerDiv = styled.div`
  aspect-ratio: 9/16;
  margin: auto;
  width: 100%;
  height: auto;

  ${device.small} {
    width: auto;
    height: 80vh;
  }
`
