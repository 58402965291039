// import dynamic from 'next/dynamic'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from '../../../client'
import { MidBoxMobileContainer } from './MidBoxMobile.style'
import { MidBox } from '../../Ads'
import { useGridStore } from '../../../store/gridStore'

export const MidBoxMobile = ({ position }: { position: number }) => {
  const className = 'mid-box-wrapper'
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const { pushAdUnit, adUnitArray } = useGridStore()
  const [render, setRender] = useState(false)
  const [index, setIndex] = useState<number>(0)
  // const MidBox = dynamic(() => import('../../Ads/MidBox/MidBox'), {
  //   ssr: false,
  // })
  const [isEmpty, setIsEmpty] = useState(true)

  const onRender = (ev: googletag.events.SlotRenderEndedEvent) => {
    setIsEmpty(ev.isEmpty)
  }

  useEffect(() => {
    if (isMobile()) {
      pushAdUnit({ id: position, adUnit: 'Mid_box' })
      setRender(true)
    }
  }, [position, pushAdUnit])

  useEffect(() => {
    if (adUnitArray.length > 0) {
      let found = 1
      for (let i = 0; i < adUnitArray.length; i++) {
        if (adUnitArray[i].adUnit === 'Mid_box') {
          if (adUnitArray[i].id === position) {
            break
          }
          found++
        }
      }
      setIndex(found)
    }
    // if (render && wrapperRef.current) {
    //   const all = document.querySelectorAll('.' + className)
    //   all.forEach((el, key) => {
    //     if (key < 4 && el === wrapperRef.current) {
    //       setIndex(key + 1)
    //     }
    //   })
    // }
  }, [adUnitArray, position])

  return (
    <MidBoxMobileContainer
      className={className}
      ref={wrapperRef}
      theme={{ isEmpty }}
    >
      {render && index && (
        <MidBox key={index} index={index} collapse={true} onRender={onRender} />
      )}
    </MidBoxMobileContainer>
  )
}
export default MidBoxMobile
