import styled, { css } from 'styled-components'
import { device } from '../../../styles/GlobalVariables'

export const VerticalPlayerBlockWrapper = styled.div``
export const VerticalPlayerSwiperWrap = styled.div`
  position: relative;

  .swiper-wrapper {
    .swiper-slide-visible:last-of-type {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }
`
export const VerticalPlayerSwiperButton = styled.div<{ role: 'next' | 'prev' }>`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  ${props =>
    props.role === 'prev'
      ? css`
          right: 0;

          svg {
            transform: rotate(-180deg);
          }
        `
      : css`
          left: 0;
        `};
  display: none;

  ${device.small} {
    display: flex;
  }
`
