import { useEffect, useState } from 'react'
import { DesktopAdId } from '../../../contexts'
import { ILayout3, IPost } from '../../../types'
import Ad from '../../Ads/Ad'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { Post } from '../../Post/Post'
import {
  Banner,
  Container,
  CustomIframe,
  PostsContainer,
} from './Layout3.styles'
import { useGridStore } from '../../../store/gridStore'

type Props = {
  layout: ILayout3
  index: number
}

export const Layout3 = ({ layout, index }: Props) => {
  const { grid_title, posts, layout_mobile, iframeUrl, isIframe } = layout
  const { pushAdUnit, adUnitArray } = useGridStore()
  const [adUnit, setAddUnit] = useState<string | null>(null)
  const [collapes, setcollapes] = useState(false)

  useEffect(() => {
    if (!isIframe) {
      pushAdUnit({ id: index, adUnit: layout.adUnit })
    }
  }, [index, isIframe, layout.adUnit, pushAdUnit])

  useEffect(() => {
    if (adUnitArray.length > 0) {
      let found = 1
      for (let i = 0; i < adUnitArray.length; i++) {
        if (adUnitArray[i].adUnit === layout.adUnit) {
          if (adUnitArray[i].id === index) {
            break
          }
          found++
        }
      }
      setAddUnit(`${layout.adUnit}_${found}`)
    }
  }, [adUnitArray, index, layout.adUnit])
  const onBannerRendered = (e: googletag.events.SlotRenderEndedEvent) => {
    if (e.isEmpty) {
      setcollapes(true)
    }
  }

  return posts ? (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <PostsContainer>
        {posts.map(
          (post: IPost, key: number) =>
            post.imageObj && (
              <Post
                key={key + ' ' + post.id}
                post={post}
                layoutMobile={layout_mobile}
                hideSecondaryTitle
              />
            )
        )}
        {isIframe ? (
          <CustomIframe src={iframeUrl} />
        ) : (
          <Banner className={layout.adUnit} theme={{ collapse: collapes }}>
            {adUnit && (
              <Ad
                id={adUnit as DesktopAdId}
                collapse={false}
                onRender={onBannerRendered}
              />
            )}
          </Banner>
        )}
      </PostsContainer>
    </Container>
  ) : null
}
