import { useFL } from '../../hooks/useFL'
import { IArticlesGrid } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { Container, Desktop, Mobile } from './ArticleGridFL.styles'
import { DesktopAllSmall } from './DesktopAllSmall/DesktopAllSmall'
import { DesktopBigFirst } from './DesktopBigFirst/DesktopBigFirst'
import { MobileNoSwiperSmall } from './MobileNoSwiperSmall/MobileNoSwiperSmall'
import { MobileSwiperBigFirst } from './MobileSwiperBigFirst/MobileSwiperBigFirst'
import { MobileSwiperSmall } from './MobileSwiperSmall/MobileSwiperSmall'

type Props = {
  layout: IArticlesGrid
}

const ArticlesGrid_fl = ({ layout }: Props) => {
  const { isDarkTheme } = useFL()

  return (
    <Container theme={{ isDarkTheme: isDarkTheme }}>
      <Desktop>
        <ComponentTitleFL
          componentTitle={layout.grid_title}
          componentId={layout.grid_id}
        />
        {layout.layout_mobile === 'first_big' ? (
          <DesktopBigFirst layout={layout} />
        ) : (
          <DesktopAllSmall layout={layout} />
        )}
      </Desktop>
      <Mobile>
        <ComponentTitleFL
          componentTitle={layout.grid_title}
          componentId={layout.grid_id}
        />
        {layout.layout_mobile === 'first_big' ? (
          <MobileSwiperBigFirst layout={layout} />
        ) : layout.swiper_mobile === 1 ? (
          <MobileSwiperSmall layout={layout} />
        ) : (
          <MobileNoSwiperSmall layout={layout} />
        )}
      </Mobile>
    </Container>
  )
}
export default ArticlesGrid_fl
