import create from 'zustand'

interface IadUnit {
  id: number
  adUnit: 'native' | 'box' | string
}

interface IGridStore {
  adUnitArray: IadUnit[]
  pushAdUnit: (newItem: IadUnit) => void
  removeAdUnit: (toRemove: IadUnit) => void
  // getAdUnitWithKey: (unit: string, key: string) => string | null
}

export const useGridStore = create<IGridStore>(set => ({
  adUnitArray: [],
  pushAdUnit: newItem =>
    set(state => {
      const exists = state.adUnitArray.some(adUnit => newItem.id === adUnit.id)
      if (!exists) {
        return { adUnitArray: [...state.adUnitArray, newItem] }
      }
      return state
    }),
  removeAdUnit: toRemove =>
    set(state => ({
      adUnitArray: state.adUnitArray.filter(
        adUnit => adUnit.id !== toRemove.id && adUnit.adUnit !== toRemove.adUnit
      ),
    })),
}))
